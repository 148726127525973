<template>
  <div v-if="isLoading">
    <div class="position-absolute w-100 h-100 text-center" style="z-index: 9">
      <div class="spinner-border text-danger me-2 align-self-center loader-lg" style="top:20%"></div>
    </div>
  </div>

  <div :style="{ opacity: isLoading ? '0.4' : '1' }">
    <ejs-grid id="tasksGrid" ref="gridTasks" :key="taskGridKey" gridLines="Both" height='101%' class="top-margin"
              :allowTextWrap='true'
              :keyPressed="onKeyDown"
              :allowExcelExport='true'
              :dataSource="tasksData"
              :editSettings="editSettings"
              :allowPaging="false"
              :pageSettings='pageSettings'
              :allowSorting='true'
              :allowFiltering='true'
              :filterSettings="filterOptions"
              :toolbar='toolbar'
              :allowReordering='true'
              :allowResizing='true'
              :showColumnChooser='true'
              :selectionSettings='selectionOptions'
              :allowSelection='true'
              :enablePersistence='true'
              :created="created"
              :actionComplete="actionComplete"
              :cellSave="onCellSave"
              :toolbarClick="toolbarClickE"
              :batchCancel="onCancelAction"
              :dataBound="dataBound">
      <e-columns>
        <e-column field='id' headerText='ID' width="85" :allowEditing="false" isPrimaryKey="true"></e-column>
        <e-column field="after_receipt" headerText="Pkt. otwarte z odbioru" type="string" editType='dropdownedit'
                  :edit="afterReceiptParams"></e-column>
        <e-column field="arrangement_description" headerText="Przypisany do ustalenia" type="string"
                  :allowEditing="false">
        </e-column>
        <e-column field="team" :edit="editParams" :valueAccessor="valueAccessor" headerText="Zespół" type="string"
                  :disableHtmlEncode="false"></e-column>
        <e-column field="task" :edit="editParams" :valueAccessor="valueAccessor" headerText="Zadanie"
                  :disableHtmlEncode="false" autoFillWidth></e-column>
        <e-column field="person_email" headerText="Osoba" type="string" :edit="personParams"></e-column>
        <e-column field="date" headerText="Data" type="date" editType='datepickeredit' :edit="editDateParams"
                  format='dd.MM.yyyy'></e-column>
        <e-column field="comments" :edit="editParams" :valueAccessor="valueAccessor" headerText="Uwagi" type="string"
                  autoFillWidth :disableHtmlEncode="false"></e-column>
        <e-column field="status" headerText="Status" type="string" editType='dropdownedit'
                  :edit="statusParams"></e-column>
        <e-column field="created_by_name" headerText="Stworzył" :allowEditing="false" type="string"></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</template>

<script>
import {MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {TextBox} from '@syncfusion/ej2-inputs';
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit,
  ExcelExport,
  Filter,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-vue-grids';
import {usePermission} from "@/composables/resources/js/composables/permissions.js";
import {Query} from "@syncfusion/ej2-data";

const {hasPermission} = usePermission();

export default {
  components: {
    hasPermission,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },
  emits: ['updateTasks'],
  props: {
    projectId: Number,
    notDoneOnly: Boolean,
    assignedToArrangement: Number,
    usersCanChange: Array,
    usersEmails: Object
  },

  mounted() {
    this.getTasks();
  },
  unmounted() {
    this.tasksData = null;
  },

  watch: {
    assignedToArrangement(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getTasks();
      }
    },
  },

  data() {
    let ddElem;
    let multiSelectObj;
    let personEmails = this.usersEmails;

    function createPersonFn() {
      ddElem = document.createElement('input');
      return ddElem;
    }

    function readPersonFn() {
      return multiSelectObj.value.join(",");
    }

    function destroyPersonFn() {
      multiSelectObj.destroy();
    }

    async function writePersonFn(args) {
      let multiSelectVal = args.rowData[args.column.field]
          ? args.rowData[args.column.field].split(",")
          : [];
      multiSelectObj = new MultiSelect({
        value: multiSelectVal,
        dataSource: personEmails,
        fields: {value: "person", text: "person"},
        floatLabelType: "Never",
        mode: "Box",
        allowCustomValue: true, // Pozwala na wpisywanie niestandardowych wartości
        customValueSpecifier: function (args) {
          // Dodaj niestandardowe wartości do listy
          personEmails.push({ person: args.text });
          args.cancel = false; // Pozwala dodać niestandardową wartość
        },
      });
      multiSelectObj.appendTo(ddElem);
    }

    let textEditor;
    let elemContent;

    return {
      getPersonParamsInterval: null,
      tasksData: null,
      isLoading: false,
      taskGridKey: 0,
      pageSettings: this.getPageSettings(),
      filterOptions: this.getFilterOptions(),
      toolbar: this.getToolbar(),
      riskRules: {required: true},
      editSettings: this.getEditSettings(),
      selectionOptions: this.getSelectionOptions(),
      personParams: {
        create: createPersonFn,
        destroy: destroyPersonFn,
        read: readPersonFn,
        write: writePersonFn
      },
      editDateParams: {
        params: {
          weekNumber: true,
          format: 'dd.MM.yyyy',
        }
      },
      editParams: {
        create: () => {
          elemContent = document.createElement("textarea");
          return elemContent;
        },
        read: () => {
          return textEditor.value;
        },
        destroy: () => {
          textEditor.destroy();
        },
        write: (args) => {
          textEditor = new TextBox({
            multiline: true,
            value: args.rowData[args.column.field],
            floatLabelType: "Auto",
          });
          textEditor.appendTo(elemContent);
        },
      },
      statusParams: this.getStatusParams(),
      afterReceiptParams: this.getAfterReceiptParams(),
    };
  },
  provide: {
    grid: [Page, Edit, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport]
  },

  methods: {
    created(args) {
      let gridInstance = document.getElementById('tasksGrid').ej2_instances[0];
      gridInstance.keyConfigs.enter = "";
    },

    valueAccessor: function (field, data, column) {
      let value = data[field];
      if (value !== undefined && value !== null) {
        return value.split("\n").join("<br>");
      } else {
        return "";
      }
    },

    onCellSave(args) {
      if (args.previousValue !== args.value) {
        this.$makeDirty();
      }
    },

    onCancelAction() {
      this.$makeClean();
    },

    getToolbar() {
      if (this.userCanModify) {
        return [
          "Add",
          {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
          "Update",
          "Cancel",
          {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
          {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
          "ColumnChooser",
        ];
      }
      return [
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'}
      ];
    },

    toolbarClickE(args) {
      if (args.item.id === 'delete_btn') {
        Toast.fire({
          toast: false,
          icon: 'warning',
          title: 'Usuwanie Wiersza',
          html: 'Na pewno chcesz usunąć wybrane wiersze?',
          showDenyButton: true,
          timer: false,
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Tak, usuń!',
          denyButtonText: 'Nie',
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRow();
          }
        });
        return false;
      }

      if (args.item.id === 'excelexport') {
        this.$refs.gridTasks.excelExport();
      }
    },

    deleteRow() {
      let self = this;
      this.isLoading = true;
      const scrollPosition = window.scrollY;
      let gridInstance = document.getElementById('tasksGrid').ej2_instances[0];
      const selectedIndexes = gridInstance.getSelectedRowCellIndexes();
      let deletedRows = selectedIndexes.map(o => gridInstance.currentViewData[o.rowIndex]);
      deletedRows = deletedRows.map(obj => obj.id); // zmiana na array tylko z id

      selectedIndexes.forEach(o => {
        gridInstance.deleteRecord('id', gridInstance.currentViewData[o.rowIndex]);
      });

      axios.delete(route('projects.show.tasks.deleteTask', {
        project: this.projectId,
        tasks: JSON.stringify(deletedRows)
      })).then(response => {
        if (response.status === 200) {
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
          });
        } else {
          console.log(response);
          Toast.fire({
            position: 'center',
            toast: false,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
            timer: false,
            showCloseButton: true
          });
        }
        self.reloadGrid();
        self.getTasks();
        self.isLoading = false;
        this.$emit('updateTasks', true);
        this.$makeClean();
        this.$nextTick(() => {
          window.scrollTo(0, scrollPosition);
        });
      }).catch(error => {
        console.log(error);
      });
    },

    dataBound() {
      let gridInstance = this.$refs.gridTasks;
      gridInstance.hideScroll();

      let rows = gridInstance.getRows();
      for (let i = 0; i < rows.length; i++) {
        const data = gridInstance.getCurrentViewRecords()[i];
        if (data.after_receipt === 'Tak') {
          rows[i].classList.add('after-receipt');
        }
      }
    },

    getTasks() {
      this.isLoading = true;
      axios.get(route('projects.get-tasks-for-project', {
        projectId: this.projectId,
        notDoneOnly: this.notDoneOnly,
        assignedToArrangement: this.assignedToArrangement
      })).then((response) => {
        this.tasksData = response.data;
      }).finally(() => {
        this.isLoading = false;
      })
    },

    actionComplete(args) {
      if (args.requestType === "batchsave") {
        this.isLoading = true;

        let newData = this.parseJsonRows(args.rows);

        // Jeżeli zadanie jest przypisane do ustalenia, to dopisz id tego ustalenia,
        // np. zmiana jest inicjowana ze strony projects/1 -> dodatkowe ustalenia
        if (this.assignedToArrangement) {
          newData.forEach((row) => {
            row.arrangement_id = this.assignedToArrangement
          })
        }
        this.updateTaskRows(newData);
      }
    },

    async updateTaskRows(rows) {
      const self = this;
      const scrollPosition = window.scrollY;
      axios.post(route('projects.show.tasks.updateTask', self.projectId), {
        'rows': rows
      }).then((response) => {
        if (response.status === 200) {
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: 'success',
            title: 'Gotowe',
            html: 'Zaktualizowano poprawnie',
          });
        } else {
          console.log(response);
          Toast.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Błąd',
            html: 'Błąd podczas aktualizacji zadań:' + response.data
          });
        }
        self.reloadGrid();
        self.getTasks();
        self.isLoading = false;
        this.$emit('updateTasks', true);
        this.$makeClean();
      }).catch(error => {
        console.error('Błąd podczas aktualizacji zadań:', error.message);
        Toast.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Błąd',
          html: 'Błąd podczas aktualizacji zadań: ' + error.message,
        });
      }).finally(() => {
        this.$nextTick(() => {
          setTimeout(function () {
            window.scrollTo(0, scrollPosition);
          }, 500)
        });
      });
    },

    parseJsonRows(rows) {
      let jsonRows = [];
      rows.forEach(function (row) {
        jsonRows.push(row.data);
      });

      return jsonRows;
    },

    reloadGrid() {
      this.taskGridKey += 1;
    },

    getPageSettings() {
      return {pageSize: 15};
    },

    getFilterOptions() {
      return {type: "Excel"};
    },

    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
      };
    },

    getSelectionOptions() {
      return {type: 'Multiple', cellSelectionMode: 'Box', mode: 'Cell'};
    },

    getStatusParams() {
      const params = {
        dataSource: [
          {status: ''},
          {status: 'anulowano'},
          {status: 'częściowo'},
          {status: 'w trakcie'},
          {status: 'zrobiono'}
        ],
        query: new Query(),
        fields: {value: 'status', text: 'status'},
        allowFiltering: false,
      };
      return {params};
    },

    getAfterReceiptParams() {
      return {
        params: {
          dataSource: [
            {after_receipt: 'Nie'},
            {after_receipt: 'Tak'},
          ],
          fields: {text: "after_receipt", value: "after_receipt"},
        },
      };
    },

    userCanModify() {
      let rights = 0;
      if (this.usersCanChange) {
        rights = this.usersCanChange.find(email => email === this.$page.props.user.email);
      }

      return !(rights === undefined || rights <= 0);
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        if (this.userCanModify) {
          // zapisz
          let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
          let buttons = toolbar.querySelectorAll('.e-toolbar-item');
          let updateButton = buttons[2].querySelector('button');
          if (updateButton) {
            updateButton.click();
          }
        }
      }
    }
  }
}
</script>

<style>
.after-receipt {
  background-color: #faecb5 !important;
}

#tasksGrid_excelexport {
  float: right;
}

[id$="_excelexport"] {
  float: right;
}
</style>
