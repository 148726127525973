<script setup>

import {onMounted, ref} from "vue";
import {useStore} from "vuex";

import {usePermission} from "../../composables/resources/js/composables/permissions.js";

const {hasRole, hasPermission} = usePermission();

const menu_collapse = ref('dashboard');
onMounted(() => {
  let domain = window.location.protocol + '//' + window.location.host;
  const selector = $('#sidebar a[href="' + domain + window.location.pathname + '"]');
  if (selector) {
    const ul = selector.closest('ul.collapse');
    if (ul) {
      let ele = ul.closest('li.menu').find('.dropdown-toggle');
      if (ele && ele.length > 0) {
        $(ul).addClass('show');
      }
    } else {
      selector.click();
    }
  }
});

const toggleMobileMenu = () => {
  if (window.innerWidth < 991) {
    useStore().commit('toggleSideBar', !useStore().state.is_show_sidebar);
  }
};

</script>

<template>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme">
    <nav ref="menu" id="sidebar">
      <div class="shadow-bottom"></div>
      <ul class="list-unstyled menu-categories" id="accordionMenu">
        <li class="menu" :class="{'active': $page.component === 'Dashboard'}">
          <Link :href="route('dashboard.index')" class="dropdown-toggle" aria-expanded="false">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-home">
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
              <span>Pulpit</span>
            </div>
          </Link>
        </li>

        <li v-if="hasPermission('canViewClients')" class="menu" :class="{'active': $page.url.startsWith('/customers')}">
          <Link href="#customers" data-bs-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-users">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <span>Kontrahenci</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </Link>
          <ul class="submenu list-unstyled collapse" id="customers" data-bs-parent="#accordionMenu" style="">
            <li :class="{'active': $page.url === '/customers'}">
              <Link :href="route('customers.index')"> Lista kontrahentów</Link>
            </li>
            <li :class="{'active': $page.url.startsWith('/customers/create')}">
              <Link :href="route('customers.create')"> Utwórz kontrahenta</Link>
            </li>
            <li v-if="hasRole('ADMIN')" :class="{'active': $page.url === '/customers-categories'}">
              <Link :href="route('customers-categories.index')">Kategorie kontrahentów</Link>
            </li>
          </ul>
        </li>

        <li v-if="hasPermission('canViewOffers')" class="menu" :class="{'active': $page.url.startsWith('/offers')}">
          <Link href="#offers" data-bs-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed"
                preverse-state>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-clipboard">
                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
              </svg>
              <span>Oferty</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </Link>

          <ul class="submenu list-unstyled collapse" id="offers" data-bs-parent="#accordionMenu" style="">
            <li v-if="hasPermission('canEditOffers')" :class="{'active': $page.url === '/offers'}">
              <Link :href="route('offers.index')"> Lista ofert</Link>
            </li>
            <li v-if="hasPermission('canEditOffers')" :class="{'active': $page.url.startsWith('/offers/create')}">
              <Link :href="route('offers.create')"> Utwórz Ofertę</Link>
            </li>
            <li v-if="hasPermission('canEditOffersTemplates')"
                :class="{'active': $page.url.startsWith('/offers/offers-templates')}">
              <Link :href="route('offers.templates.show')">Edycja szablonów</Link>
            </li>
          </ul>
        </li>

        <li class="menu" :class="{'active': $page.url.startsWith('/projects')}">
          <Link href="#projects" data-bs-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-box">
                <path
                    d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                <line x1="12" y1="22.08" x2="12" y2="12"></line>
              </svg>
              <span>Projekty</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </Link>
          <ul class="submenu list-unstyled collapse" id="projects" data-bs-parent="#accordionMenu" style="">
            <li :class="{'active': $page.component === 'Projects/Index' || $page.component === 'Projects/Show' || $page.component.startsWith('Projects/project')}">
              <Link :href="route('projects.index')"> Lista projektów</Link>
            </li>
            <li :class="{'active': $page.url === '/projects/create'}" v-if="hasPermission('canEditBasicProject')">
              <Link :href="route('projects.create')"> Utwórz projekt</Link>
            </li>
            <li :class="{'active': $page.component === 'Glowice/Index' || $page.component === 'Glowice/Show' || $page.component.startsWith('Glowice/project')}">
              <Link :href="route('glowice.index')"> Lista Głowic</Link>
            </li>
            <li :class="{'active': $page.component === 'Projects/Index/ResourceSchedule'}"
                v-if="hasPermission('canViewScheduleProject')">
              <Link :href="route('projects.projects-resourceSchedule')"> Harmonogram zasobów</Link>
            </li>
            <li :class="{'active': $page.component === 'Projects/Index/PaymentsStatus'}"
                v-if="hasPermission('canViewPaymentsProject')">
              <Link :href="route('projects.projects-paymentsStatus')">Status płatności</Link>
            </li>
            <li :class="{'active': $page.component === 'Projects/Index/Warranty'}"
                v-if="hasPermission('canViewWarrantyProject')">
              <Link :href="route('projects.projects-warranty')">Gwarancja</Link>
            </li>
            <li :class="{'active': $page.component === 'Projects/DefaultSuppliers/Index'}"
                v-if="hasRole('ADMIN')">
              <Link :href="route('projects.default-suppliers.index')">Domyślni Dostawcy</Link>
            </li>
          </ul>
        </li>

        <li v-if="hasRole('ADMIN')" class="menu" :class="{'active': $page.url.startsWith('/users')}">
          <Link href="#users" data-bs-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-users">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <span>Użytkownicy</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </Link>
          <ul class="submenu list-unstyled collapse" id="users" data-bs-parent="#accordionMenu" style="">
            <li :class="{'active': $page.url === '/users'}">
              <Link :href="route('users.index')"> Lista użytkowników</Link>
            </li>
            <li :class="{'active': $page.url.startsWith('/users/create')}">
              <Link :href="route('users.create')" preserve-state> Utwórz użytkownika</Link>
            </li>
            <li :class="{'active': $page.url.startsWith('/users/departments')}">
              <Link :href="route('users.departments.index')" preserve-state> Działy użytkowników</Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
  <!--  END SIDEBAR  -->
</template>

<script>
import route from "../../../../vendor/tightenco/ziggy/src/js/index.js";

export default {
  methods: {route},
}
</script>
